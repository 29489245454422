<template>
  <div
    class="relative rounded-4xl border border-outlineGray overflow-hidden shadow-sm hover:shadow-xl transition duration-300"
    @mouseover="toggleHover(true)"
    @mouseleave="toggleHover(false)"
  >
    <div
      class="bg-white rounded-full absolute left-5 top-3 w-16 h-16 flex items-center justify-center"
    >
      <img :src="IconSrc" alt="Card Image" class="w-5 h-6" />
    </div>
    <img
      :src="isHovered ? secondImage : firstImage"
      alt="Card Image"
      class="w-full h-auto object-cover"
    />
    <div class="pt-2 pb-6 px-6">
      <p class="pb-3 text-primary-deep font-medium text-lg">
        {{ title }}
      </p>
      <p class="text-grey font-normal text-sm">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
defineProps({
    IconSrc: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
});
const isHovered = ref(false);

import firstImage from "../pages/mgt/assets/inActiveState.svg";
import secondImage from "../pages/mgt/assets/activeState.svg";

const toggleHover = (value) => {
  isHovered.value = value;
};
</script>

<style scoped></style>
